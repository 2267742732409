<template>
  <div class="clients-page">
    <h1>Lista Clienti</h1>

    <!-- Barra di ricerca e filtro -->
    <div class="search-bar">
      <input
        type="text"
        placeholder="Cerca..."
        v-model="searchQuery"
        @input="fetchClients"
      />
      <select v-model="clientTypeFilter" @change="fetchClients" class="filter-select">
        <option value="">Tutti i tipi</option>
        <option value="AZIENDA">Azienda</option>
        <option value="PRIVATO">Privato</option>
      </select>
    </div>

    <!-- Indicatore di contatore -->
    <p class="counter">Visualizzati {{ clients ? clients.length : 0 }} clienti su {{ totalClients }}</p>

    <!-- Tabella dei clienti -->
    <table class="clients-table">
      <thead>
        <tr>
          <th @click="sortBy('name')" class="sortable">
            Nome
            <span class="sort-icon">
              <svg v-if="sortKey === 'name' && sortOrders.name === 1" width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 14L12 9L17 14H7Z" />
              </svg>
              <svg v-else width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 10L12 15L17 10H7Z" />
              </svg>
            </span>
          </th>
          <th @click="sortBy('email')" class="sortable">
            Email
            <span class="sort-icon">
              <svg v-if="sortKey === 'email' && sortOrders.email === 1" width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 14L12 9L17 14H7Z" />
              </svg>
              <svg v-else width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 10L12 15L17 10H7Z" />
              </svg>
            </span>
          </th>
          <th @click="sortBy('phone')" class="sortable">
            Telefono
            <span class="sort-icon">
              <svg v-if="sortKey === 'phone' && sortOrders.phone === 1" width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 14L12 9L17 14H7Z" />
              </svg>
              <svg v-else width="20" height="20" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7 10L12 15L17 10H7Z" />
              </svg>
            </span>
          </th>
          <th class="sortable">Tipo Cliente</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="client in clients" :key="client.id">
          <td>{{ client.clientName }}</td>
          <td><a :href="'mailto:' + client.email" target=”_blank”>{{ client.email }}</a></td>
          <td><a :href="'tel: ' + client.phoneNumbers[0].number" target=”_blank”>{{ client.phoneNumbers[0].number }}</a></td>
          <td>
            <span :class="['badge', client.companyName ? 'company' : 'private']">
              {{ client.companyName ? 'Azienda' : 'Privato' }}
            </span>
          </td>
          <td>
            <button @click="openClient(client._id)" class="btn-action btn-view" title="Apri">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5C7.58 5 4 8.03 2 12C4 15.97 7.58 19 12 19C16.42 19 20 15.97 22 12C20 8.03 16.42 5 12 5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" />
              </svg>
            </button>
            <button @click="editClient(client._id)" class="btn-action btn-edit" title="Modifica">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z" />
              </svg>
            </button>
            <button @click="deleteClient(client._id, client.clientName)" class="btn-action btn-delete" title="Elimina">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 3V4H4V6H20V4H15V3H9Z" />
                <path d="M10 9V17H8V9H10ZM14 9V17H12V9H14ZM16 9V17H18V9H16ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19Z" />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Paginazione -->
    <div class="pagination">
      <button @click="prevPage" :disabled="page === 1">Precedente</button>
      <span>Pagina {{ page }} di {{ totalPages ? totalPages : 1 }}</span>
      <button @click="nextPage" :disabled="page === totalPages">Successivo</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      searchQuery: '',
      clientTypeFilter: '',
      clients: [],
      totalClients: 0,
      page: 1,
      limit: 10, // Numero di clienti per pagina
      totalPages: 1,
      sortKey: '',
      sortOrders: {
        name: -1,
        email: -1,
        phone: -1
      }
    };
  },
  mounted() {
    this.fetchClients();
  },
  methods: {
    async fetchClients() {
      try {
        const response = await axios.get('https://18.102.49.68:3000/api/clients/list', {
          params: {
            page: this.page,
            limit: this.limit,
            searchQuery: this.searchQuery,
            clientType: this.clientTypeFilter,
            sortKey: this.sortKey,
            sortOrder: this.sortOrders[this.sortKey] || 1
          }
        });
        this.clients = response.data;
        this.totalClients = response.data.length;
        this.totalPages = Math.ceil(this.totalClients / this.limit);
      } catch (error) {
        console.error('Errore durante il recupero dei clienti:', error);
      }
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.fetchClients();
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.fetchClients();
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.fetchClients();
      }
    },
    openClient(clientId) {
      // Implementazione per aprire i dettagli del cliente
      this.$router.push(`/client/${clientId}`);
    },
    editClient(clientId) {
      // Implementazione per modificare il cliente
    },
    deleteClient(clientId, clientName) {
      if (confirm(`Sei sicuro di voler eliminare il cliente con Nome: ${clientName}?`)) {
        axios.delete(`https://18.102.49.68:3000/api/clients/delete/${clientId}`)
          .then(() => {
            this.fetchClients();
          })
          .catch(error => {
            console.error('Errore durante l\'eliminazione del cliente:', error);
          });
      }
    }
  }
};
</script>

<style scoped>
.clients-page {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #991520;
}

/* Barra di ricerca e filtro */
.search-bar {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  gap: 10px;
}

.search-bar input {
  padding: 8px;
  width: 150px;
  border: 1px solid #ddd;
  border-radius: 25px;
  outline: none;
  font-size: 14px;
  transition: width 0.3s ease, border-color 0.3s;
}

.search-bar input:focus {
  width: 200px;
  border-color: #991520;
}

.filter-select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  cursor: pointer;
}

.btn-create {
  background-color: #28a745;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.btn-create:hover {
  background-color: #218838;
}

/* Indicatore del contatore */
.counter {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

/* Tabella dei clienti */
.clients-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 14px;
}

.clients-table th,
.clients-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.clients-table th.sortable {
  background-color: #991520;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
}

.clients-table th.sortable:hover {
  background-color: #AE1100;
}

/* Stile per l'icona di ordinamento */
.sort-icon {
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}

/* Badge tipo cliente */
.badge {
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.badge.private {
  background-color: #28a745; /* Verde per "Privato" */
}

.badge.company {
  background-color: #007bff; /* Blu per "Azienda" */
}

/* Bottoni di azione */
.btn-action {
  padding: 5px;
  margin: 0 2px;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.btn-view {
  background-color: #28a745; /* Verde */
  color: white;
}

.btn-edit {
  background-color: #007bff; /* Blu */
  color: white;
}

.btn-delete {
  background-color: #dc3545; /* Rosso */
  color: white;
}

.btn-action svg {
  width: 16px;
  height: 16px;
  transition: transform 0.2s;
}

.btn-action:hover svg {
  transform: scale(1.1);
}

/* Hover row */
.clients-table tbody tr:hover {
  background-color: #f9f9f9;
}

/*PAGINAZIONE*/
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #991520;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
}
</style>
